// extracted by mini-css-extract-plugin
export var address_chain_icon_container = "index-module--address_chain_icon_container--uXvTf";
export var address_container = "index-module--address_container--4RL-h";
export var address_content = "index-module--address_content--SEoEF";
export var chainbox_container = "index-module--chainbox_container--kb1e8";
export var data_container = "index-module--data_container--2W5Om";
export var data_container_left = "index-module--data_container_left--6GI4w";
export var data_container_right = "index-module--data_container_right--aEGcJ";
export var data_outer_container = "index-module--data_outer_container--Zj4Y9";
export var data_right = "index-module--data_right--7plF3";
export var delete_button = "index-module--delete_button--hVt39";
export var dropdown_button = "index-module--dropdown_button--YHffI";
export var flex_row = "index-module--flex_row--LZWZN";
export var hidden_content = "index-module--hidden_content--cDTwu";
export var property_container = "index-module--property_container--8YOzY";
export var property_list_item = "index-module--property_list_item--CaxTL";
export var royaltypreselect_container = "index-module--royaltypreselect_container--X1USM";