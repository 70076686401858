import React from "react";
import Web3Eth from "web3-eth";
import { navigate } from "gatsby";
import { withAuthentication } from "../../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import ScreenLoad from "../../../components/screenLoad";
import Main from "../../../components/main";
import Button from "../../../components/button";
import Input from "../../../components/input";
import LinkWithIcon from "../../../components/linkWithIcon";
import NotFoundPage from "../../../components/notFoundPage";
import SignOverlay from "../../../components/signOverlay";
import ContentArea from "../../../components/contentArea";
import MediaStickyPreview from "../../../components/mediaStickyPreview";
import TextArea from "../../../components/textArea";
import Consts from "../../../config/consts";
import Common from "../../../config/common";
import Provider from "../../../config/provider";
import Exchange from "../../../config/exchange";
import Util from "../../../config/util";
import Api from "../../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faCrown, faPencilAlt, faArrowRight, faArrowUp91, faLock, faTimes, faArrowDown, faChevronDown } from "@fortawesome/free-solid-svg-icons";

/*
    The minting page is used for all cases where some minting may be used, this means
    it covers the follows instances:
    + User wishes to mint now
    + User wants to list for sale and "lazy" mint


    Scenario 1
    + User mints
    + User lists (optional)

    Scenario 2
    + User lists
    + Mint on sale


    Mint Page
    List Page

*/
export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class MediaMint extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                initialising: true,
                incomplete: true,
                media: null,
                minting: false,
                isLazyMinting: false,
                requiresSigning: false,
                mediaId: props.params.media_id,

                selectedMintingType: "Lazy Mint",
                mintingOptions: [
                    {
                        name: "Lazy Mint",
                        icon: faClock,
                        cost: "Free",
                    },
                    {
                        name: "Mint Now",
                        icon: faArrowDown,
                        cost: "Costs",
                    },
                ],

                selectedAddress: props.auth.user.addresses[0],
                addresses: props.auth.user.addresses,

                locked: false,

                royaltiesPercent: 0,
                supply: 1,
                attributes: [{ key: "", value: "" }],
                hiddenContent: "",

                hiddenContentVisible: false,
            };
        }

        componentDidMount() {
            Api.market({
                endpoint: "/content/media",
                method: "GET",
                data: {
                    mediaId: this.props.params.media_id,
                },
            })
                .then((media_res) => {
                    if (Common.objectPropertyExists(media_res, "data.created", false) !== true) {
                        navigate(`/m/${this.state.mediaId}`);
                    } else {
                        let media_attributes = Common.objectPropertyExists(media_res, "data.attributes", null);
                        let media_royalties = Common.objectPropertyExists(media_res, "data.royaltiesPercent", null);
                        let media_supply = Common.objectPropertyExists(media_res, "data.totalSupply", null);
                        this.setState(
                            {
                                initialising: false,
                                media: media_res.data,
                                royaltiesPercent: media_royalties !== null ? media_royalties : 0,
                                supply: media_supply !== null ? media_supply : 1,
                                attributes: Array.isArray(media_attributes) ? media_attributes : [{ key: "", value: "" }],
                                hiddenContent: Common.objectPropertyExists(media_res, "data.hiddenContent", ""),
                            },
                            () => {
                                this.validate();
                            }
                        );
                    }
                })
                .catch((e) => {
                    this.setState({
                        initialising: false,
                    });
                });
        }

        selectAddress = (address) => {
            this.setState(
                {
                    selectedAddress: address,
                },
                () => {
                    this.validate();
                }
            );
        };

        mintingTypeSelected = (mintingType) => {
            this.setState(
                {
                    selectedMintingType: mintingType,
                },
                () => {
                    this.validate();
                }
            );
        };

        onPreSelectRoyalties0 = () => {
            this.onRoyaltyPercentChanged(0);
        };
        onPreSelectRoyalties10 = () => {
            this.onRoyaltyPercentChanged(10);
        };
        onPreSelectRoyalties20 = () => {
            this.onRoyaltyPercentChanged(20);
        };
        onPreSelectRoyalties30 = () => {
            this.onRoyaltyPercentChanged(30);
        };
        onPreSelectRoyalties40 = () => {
            this.onRoyaltyPercentChanged(40);
        };
        onPreSelectRoyalties50 = () => {
            this.onRoyaltyPercentChanged(50);
        };
        onRoyaltyPercentChanged = (text) => {
            if (isNaN(text)) {
                Util.notify.error("Invalid percent entered, please enter a value between 0 and");
            } else {
                this.setState({
                    royaltiesPercent: Number(text),
                });
            }
        };

        onSupplyChanged = (text) => {
            this.setState({
                supply: text,
            });
        };

        onPropertyKeyChange = (text, index) => {
            this.setState(
                {
                    attributes: [
                        ...this.state.attributes.map((p, i) => {
                            if (i === index) {
                                return {
                                    key: text,
                                    value: p.value,
                                };
                            }
                            return p;
                        }),
                    ],
                },
                () => {
                    // Check if we have an empty row - if not lets add one in
                    if (this.state.attributes.filter((p) => p.key === "" && p.value === "").length <= 0) {
                        this.setState({
                            attributes: [...this.state.attributes, ...[{ key: "", value: "" }]],
                        });
                    }
                }
            );
        };
        onPropertyValueChange = (text, index) => {
            this.setState(
                {
                    attributes: [
                        ...this.state.attributes.map((p, i) => {
                            if (i === index) {
                                return {
                                    key: p.key,
                                    value: text,
                                };
                            }
                            return p;
                        }),
                    ],
                },
                () => {
                    // Check if we have an empty row - if not lets add one in
                    if (this.state.attributes.filter((p) => p.key === "" && p.value === "").length <= 0) {
                        this.setState({
                            attributes: [...this.state.attributes, ...[{ key: "", value: "" }]],
                        });
                    }
                }
            );
        };
        onDeleteProperty = (index) => {
            if (this.state.attributes.length <= 1) {
                this.setState({
                    attributes: [{ key: "", value: "" }],
                });
            } else {
                this.setState({
                    attributes: this.state.attributes.filter((p, i) => {
                        return i !== index;
                    }),
                });
            }
        };

        onHiddenContentChanged = (text) => {
            this.setState({
                hiddenContent: text,
            });
        };

        hideSignOverlay = () => {
            this.setState({
                minting: false,
                requiresSigning: false,
            });
        };

        onRequestMint = () => {
            if (this.state.minting !== true) {
                this.setState({
                    minting: true,
                    requiresSigning: true,
                });
            }
        };

        onSigned = async (signature) => {};
        onMinted = async (error) => {
            if (!error) {
                setTimeout(() => {
                    navigate(`/m/${this.state.mediaId}`);
                }, 2000);
            } else {
                this.setState({
                    minting: false,
                    requiresSigning: false,
                });
            }
        };

        onMouseOverHiddenContent = () => {
            clearTimeout(this.hiddenContentVisibleTimeout);
            this.setState(
                {
                    hiddenContentVisible: true,
                },
                () => {
                    this.hiddenContentVisibleTimeout = setTimeout(() => {
                        this.setState({
                            hiddenContentVisible: false,
                        });
                    }, 4000);
                }
            );
        };

        validate = () => {
            this.setState({
                incomplete: this.state.selectedAddress === null,
            });
        };

        refreshPage = () => {
            window.location.reload();
        };

        render() {
            return this.state.media === null ? (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <NotFoundPage notFoundName={"Media"} />
                </Main>
            ) : (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    {this.state.media.mintingSetup !== true ? (
                        <ContentArea centered={true} extraTopPadding={true} slim={true}>
                            <ContentArea bottomRule={false}>
                                <TextArea center={true}>
                                    <p className={"caps_and_spaced"}>Media Minting</p>
                                </TextArea>
                            </ContentArea>
                            <ContentArea header={"Action Required"} slim={true} bottomRule={true}>
                                <TextArea header={"Mint Setup Needed"}>
                                    <p>
                                        Before you can mint your media we need to setup minting, this will inform us of how you want to mint. You can change your mint options up until the point your
                                        media has been minted or listed for sale.
                                    </p>
                                </TextArea>
                                <TextArea>
                                    <Button displayMode={5} hoverMode={6} text={"Setup Mint Options"} to={`/m/mint/setup/${this.state.mediaId}`} noMargin={true} />
                                </TextArea>
                            </ContentArea>
                        </ContentArea>
                    ) : this.state.media.featuredListing && this.state.media.featuredListing.state !== "NOT_FOR_SALE" && this.state.media.featuredListing.state !== "REMOVED_FROM_SALE" ? (
                        <ContentArea centered={true} extraTopPadding={true} slim={true}>
                            <ContentArea bottomRule={false}>
                                <TextArea center={true}>
                                    <p className={"caps_and_spaced"}>Media Minting</p>
                                </TextArea>
                            </ContentArea>
                            <ContentArea header={"Invalid State"} slim={true} bottomRule={true}>
                                <TextArea header={"Cannot Mint"}>
                                    <p>Your media is currently not in a 'not for sale' state - media in this state can not be minted, this is to protect on-going actions such as lazy minting.</p>
                                </TextArea>
                                <TextArea>
                                    <Button displayMode={5} hoverMode={6} text={"View Media"} to={`/m/${this.state.mediaId}`} noMargin={true} />
                                </TextArea>
                            </ContentArea>
                        </ContentArea>
                    ) : (
                        <ContentArea centered={true} extraTopPadding={true} slim={true}>
                            <ContentArea header={"Minting Options."} slim={true} bottomRule={true}>
                                <TextArea>
                                    <p>The following are the settings we will apply to your media once minted, these can be changed up until the point the media is minted or lazy minted.</p>
                                    <p>
                                        <b>Public Address:</b>&nbsp;{`${Common.cropTo(this.state.media.creator.publicAddress, 17)}`}
                                        <br />
                                        <b>Royalties:</b>&nbsp;{this.state.media.royaltiesPercent}%
                                        <br />
                                        <b>Supply:</b>&nbsp;{this.state.media.totalSupply}
                                    </p>
                                    <br />
                                    <Button displayMode={5} hoverMode={6} text={"Edit Minting Options"} to={`/m/mint/setup/${this.state.media.mediaId}`} noMargin={true} />
                                </TextArea>
                                <br />
                            </ContentArea>
                            <ContentArea header={"Please Note."} slim={true} bottomRule={true}>
                                <TextArea>
                                    <p>
                                        By default we will <b>'Lazy Mint'</b> your media upon listing for sale. Lazy Minting is a process where we hold off minting until the point of sale to avoid
                                        costing you (the seller) the minting gas fee, this cost is instead seamlessly transferred to the buyer at the point of purchase.
                                        <br />
                                        <br />
                                        Alternatively you can choose to continue to <b>'Mint Now'</b> however please note that you will be required to pay the mint gas fee yourself.
                                    </p>
                                    <br />
                                </TextArea>
                            </ContentArea>

                            <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                                <TextArea center={true}>
                                    <div className={[styles.flex_row, styles.center]}>
                                        <Button displayMode={8} hoverMode={4} disabled={this.state.minting} text={"Cancel"} to={`/m/${this.state.mediaId}`} />
                                        <Button
                                            displayMode={5}
                                            hoverMode={6}
                                            disabled={this.state.minting || this.state.incomplete}
                                            text={this.state.minting ? "Minting..." : "Mint Now"}
                                            onClick={this.onRequestMint}
                                        />
                                    </div>
                                </TextArea>
                            </ContentArea>
                        </ContentArea>
                    )}
                    <MediaStickyPreview
                        media={this.state.media}
                        mediaId={this.state.mediaId}
                        image={`${process.env.GATSBY_STORAGE}media/${this.state.media.displayImage}`}
                        text={this.state.media.name}
                    />

                    {this.state.requiresSigning && (
                        <SignOverlay
                            type={"MINT"}
                            media={this.state.media}
                            addresses={this.props.auth.user.addresses}
                            signBy={this.state.media.creator.publicAddress}
                            onSigned={this.onSigned}
                            onMinted={this.onMinted}
                            onClose={this.hideSignOverlay}
                            currentChain={this.props.currentChain}
                            chains={this.props.chains}
                        />
                    )}
                </Main>
            );
        }
    }
);
